import React from 'react';

import { Box, useTheme } from '@chakra-ui/react';

import { Header } from '@/components/Layout';

const Page = ({ children, withHeader = true, ...rest }) => {
  const theme = useTheme();
  const navHeight = withHeader ? theme.header.height : 0;
  return (
    <Box pt={`${navHeight}px`} {...rest}>
      {withHeader && <Header />}
      {children}
    </Box>
  );
};

export default Page;
