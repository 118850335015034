import { Box, BoxProps } from '@chakra-ui/react';
import { motion } from 'framer-motion';

export const FadeIn = ({
  children,
  seconds,
  ...rest
}: {
  children: React.ReactNode;
  seconds?: number;
} & BoxProps) => {
  return (
    <Box
      as={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={`${seconds || 0.8}s easeInOut`}
      {...rest}
    >
      {children}
    </Box>
  );
};

export const FadeInFromTop = ({
  children,
  seconds,
  ...rest
}: {
  children: React.ReactNode;
  seconds?: number;
} & BoxProps) => {
  return (
    <Box
      as={motion.div}
      position={'relative'}
      initial={{ top: '-10px', opacity: 0 }}
      animate={{ top: '0', opacity: 1 }}
      transition={`${seconds || 0.8}s easeIn`}
      {...rest}
    >
      {children}
    </Box>
  );
};
